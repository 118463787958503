import { CHAIN_ID_SUPPORT_PAGES } from 'config';
import { GetWhitelist } from 'state/auth';

import { Login, HomeFundGo, HomeTrustPay } from "views";

const IndexPage = () => {
    const data = GetWhitelist()
    if (data?.dataUser?.type === 1) return <HomeFundGo />
    if (data?.dataUser?.type === 2) return <HomeTrustPay />
    return <Login />
}
IndexPage.chains = CHAIN_ID_SUPPORT_PAGES

export default IndexPage
